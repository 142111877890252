const features = document.querySelectorAll<HTMLDivElement>("div.feature");

features.forEach((feature) => {
  feature.addEventListener("mouseover", (e: MouseEvent) => {
    features.forEach((feature) => {
      feature.classList.remove("active");
    });
    const targetFeature = (e.target as HTMLElement).closest("div.feature") as HTMLDivElement;
    if (targetFeature) {
      targetFeature.classList.add("active");
    }
  });
});